
import { ENUMVoucherTypes } from "@/Interfaces/EnumVouchers";
import { toRef } from "vue";
import LevelComponent from "@/components/level/LevelComponent.vue";
import LevelItemComponent from "@/components/level/LevelItemComponent.vue";
import IconByImage from "@/components/elements/IconByImage.vue";

export default {
  name: "IconByFALabeled",
  components: {
    LevelComponent,
    LevelItemComponent,
  },
  props: {
    label: {
      type: String,
      required: true,
    },
    icon: {
      type: String,
      default: undefined,
    },
  },
  setup(props: any) {
    return {};
  },
};
