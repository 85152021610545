
import {inject, onBeforeMount, ref, Ref, watch} from "vue";
import {useRoute, useRouter} from "vue-router";

import BasicSelectComponent from "@/components/elements/BasicSelectComponent.vue";
import IconByFALabeled from "@/components/elements/IconByFALabeled.vue";

import {useCategories} from "@/composables/CategoriesComposable";
import {categoryStore} from "@/stores/CategoryStore";
import {useI18n} from "vue-i18n";

export default {
  name: "CategoryComponent",
  components: {
    BasicSelectComponent,
    IconByFALabeled,
  },
  props: {
    selectedVoucherType: {
      type: String,
      required: false,
      default: undefined,
    },
    selectedCategory: {
      type: String,
      required: false,
      default: undefined,
    },
  },
  emits: ["change", "reset"],

  setup(props: any, {emit}: any) {
    /**
     * Define the stores we need during the execution of the component
     */
    const vouchersStore: any = inject("vouchersStore");
    const categoryStore: any = inject("categoryStore");
    const placeStore: any = inject("placeStore");
    const searchStore: any = inject("searchStore");
    const langStore: any = inject("langStore");
    const userStore: any = inject("userStore");

    /**
     * Define all the models we might need to keep reactive data
     */
    const localModel: Ref<any> = ref();
    const realValue: Ref<any> = ref();
    const router = useRouter();
    const route = useRoute();
    const {t} = useI18n({useScope: "global"});

    /**
     * Load all the composables
     */
    const {load} = useCategories();
    //const {loadAllCategories} = useAllCategories(); #todo handle or cleanup


    /**
     * Clear category selection functionality
     */
    async function clearCategory(): Promise<void> {
      const voucher = parseInt(props.selectedVoucherType);
      const voucher_type = vouchersStore.actions.findElement(voucher)?.ico;

      localModel.value = null;

      await categoryStore.actions.resetCategories();

      userStore.setters.showOverlay(true);
      await load(langStore.getters.getCurrentLang, voucher_type).finally(async () => {
        await categoryStore.setters.setSelected(null);
        userStore.setters.showOverlay(false);

        let newQuery = route.query;
        delete route.query.category;

        await router.push({
          //name: route.path,
          query: {
          ...route.query
          }
        });

        emit("change");
      })
    }

    onBeforeMount(async () => {
      const voucher = props.selectedVoucherType ? parseInt(props.selectedVoucherType) : undefined;
      if (voucher) {
        const voucher_type = vouchersStore.actions.findElement(voucher)?.ico;
        await load(langStore.getters.getCurrentLang, voucher_type).finally(
            () => {
              if ( ['ecv', 'activ', 'ppp'].includes(voucher_type) ) { // in this voucher type, the elements are strings.
                localModel.value = props.selectedCategory ? props.selectedCategory : undefined;
              } else {
                localModel.value = props.selectedCategory ? parseInt(props.selectedCategory) : undefined;
              }
            }
        );
      }
    });

    // watch what happens when voucher type is changed
    watch(() => props.selectedVoucherType, async (newVoucherType, oldVoucherType) => {
          const vtId = newVoucherType as unknown as string;

          userStore.setters.showOverlay(true);

          await load(langStore.getters.getCurrentLang, vouchersStore.actions.findElement(parseInt(vtId))?.ico)
              .finally(async () => {
                userStore.setters.showOverlay(false);
                localModel.value = undefined;
                emit("change");
              });
        }
    );

    /**
     * Detect the hooks which follow the reactivity changes
     */
    watch(localModel, async (newValue, oldValue) => {
      console.log("Change of category in model.")
      realValue.value = newValue;
      //searchStore.setters.setSearchCriteria("");
      placeStore.actions.clearFindings();
      await categoryStore.setters.setSelected(realValue.value);


      router.push({
        path: route.path,
        query: {
          ...route.query,
          category: newValue,
          //search_type: undefined,
          //search_criteria: undefined,
          //from_search: undefined
        },
        replace: true
      });
      emit("change");
    });


    return {
      localModel,
      categoryStore,
      vouchersStore,
      clearCategory,
      t,
    };
  },
};

