<template>
<div class="overlay">
    <div class="has-text-centered is-size-3 has-text-weight-bold has-text-black px-2">
      {{ $t("loading") }}
    </div>
</div>
<!-- <div class="hero is-fullheight" style="z-index: 99999">
  <div class="hero-body">

    <div class="has-text-centered has-text-danger p-2 mt-2">
      {{ $t("loading") }}
    </div>
  </div>
</div> -->
</template>

<style lang="scss" scoped>
  .overlay {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(255, 255, 255, 0.7);
    z-index: 99999;
    padding-top: calc(50vh - 2.5rem);
  }
</style>