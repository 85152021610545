import {
  inject,
} from "vue";
import { AxiosResponse } from "axios";
import http from "@/services/http";
import { ENUMVoucherTypes } from "@/Interfaces/EnumVouchers";
import { ICategoryEntity } from "@/Interfaces/CategoryInterfaces";
import { ECVCategoryMappings } from '@/assets/ecv_category_mappings';

enum Lang {
  EN = "en",
  FR = "fr",
  NL = "nl",
}


export function useCategories() {
  const categoryStore: any = inject("categoryStore");
  const langStore: any = inject("langStore");

  /**
   * Extract all the categories from the given API
   *
   * @param lang
   * @param product
   */
  const getAll = async (
    lang: string,
    product: ENUMVoucherTypes
  ): Promise<AxiosResponse<ICategoryEntity[]>> => {
      console.log('start getting categories (composable -> getAll)')

    return await http
      .get("/" + lang + "/api/acceptancepoint/categories", {
        params: {
          product: product,
        },
      })
      .then((value): AxiosResponse<ICategoryEntity[]> => {
          console.log('got category response')
          console.log(value.data.data)
        return value.data.data;
      });
  };

  /**
   * Load all the categories requested for language and product type
   * @param lang
   * @param product
   */
  const load = async (lang: string, product: ENUMVoucherTypes) => {
      console.log('start Load function')
    categoryStore.setters.setCategories(
        await getAll(lang.split('-')[0], product).then((value:any) => {
            console.log("got getAll response");
          return value.map((element:ICategoryEntity) => {
                const res =  {
                    // value: product != 'ecv' ? element.id : element.id + 1, // this was a part of the workaround for Corona subcategories.
                    value: element.id ,
                    label: element.label,
                }
              return res
            })
        })
    );

  };

  /**
   * Convert ECV category id to desired string for API requests
   */
  const ecvCatIdToString = (catId: string) => {
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    return ECVCategoryMappings.find((cat_map: {id: number, code: string}) => cat_map.id === parseInt(catId)).code;
  };

  /**
   * Convert ECV desired string to category id
   */
  const ecvStringToCatId = (ecvString: string | undefined):number => {
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    return ECVCategoryMappings.find((cat_map: {id: number, code: string}) => cat_map.code === ecvString)?.id;
  };

  return {
    getAll,
    load,
    ecvCatIdToString,
    ecvStringToCatId,
  };

}
