
import { ref, Ref } from "vue";
import i18n from "@/i18n";

export default {
  name: "BaseNavbar",
  components: {},
  props: {
    name: {
      type: String,
      required: true,
    },
  },
  setup() {
    const menuToggle: Ref<boolean> = ref(false);
    const switchLanguage = (locale: 'en-BE' | 'fr-BE' | 'nl-BE') => {
      i18n.global.locale.value = locale;
    };

    return {
      menuToggle,
      switchLanguage,
    };
  },
};
