
import BaseNavbar from "@/components/navigations/BaseNavbar.vue";
import { ref, Ref } from "vue";
import BaseNavbarBurger from "@/components/navigations/BaseNavbarBurger.vue";
import LocaleChange from "@/components/LocaleChange.vue";
import {useRoute, useRouter} from "vue-router";

export default {
  name: "MainNavbar",
  components: { BaseNavbar, LocaleChange },

  setup() {
    const menuToggle: Ref<boolean> = ref(false);
    const router = useRouter();
    const route = useRoute();

    const goHome:any = async ():Promise<void> => {
      await router.push({
        name: "Home",
        query: {},
        params: {
          lang: route.params.lang
        }
      });
    };

    return {
      menuToggle,
      goHome
    };
  },
};
