import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, normalizeClass as _normalizeClass } from "vue"

const _hoisted_1 = ["onClick"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(true), _createElementBlock(_Fragment, null, _renderList($setup.langStore.getters.languages, (lang, i) => {
    return (_openBlock(), _createElementBlock("div", {
      key: _ctx.$i18n.locale + lang.label + i,
      ref: lang.label.toLowerCase(),
      class: _normalizeClass(["navbar-item has-text-weight-bold is-tab is-clickable", {'is-active': _ctx.$route.params.lang == lang.value}]),
      onClick: ($event: any) => ($setup.changeLang(lang.value))
    }, _toDisplayString(lang.label), 11, _hoisted_1))
  }), 128))
}