import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, normalizeClass as _normalizeClass, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, withScopeId as _withScopeId, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

_pushScopeId("data-v-b17ac708")
const _hoisted_1 = { class: "container is-max-mobile" }
const _hoisted_2 = { class: "tabs is-toggle mb-0 mr-4 is-inline-flex" }
const _hoisted_3 = { class: "" }
const _hoisted_4 = ["href", "onClick"]
const _hoisted_5 = ["href"]
_popScopeId()

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_router_link = _resolveComponent("router-link")!
  const _component_VoucherSelectComponent = _resolveComponent("VoucherSelectComponent")!
  const _component_CategoryComponent = _resolveComponent("CategoryComponent")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("ul", _hoisted_3, [
        _createVNode(_component_router_link, {
          to: { name: 'Home' },
          "active-class": "is-active",
          custom: ""
        }, {
          default: _withCtx(({ href, navigate, isExactActive }) => [
            _createElementVNode("li", {
              class: _normalizeClass([ $props.isHome && 'is-active', isExactActive && 'router-link-exact-active', ])
            }, [
              _createElementVNode("a", {
                href: href,
                onClick: navigate
              }, _toDisplayString(_ctx.$t('shops')), 9, _hoisted_4)
            ], 2)
          ]),
          _: 1
        }),
        _createVNode(_component_router_link, {
          to: _ctx.$t('spendOnline.link'),
          "active-class": "is-active",
          key: _ctx.$i18n.locale+'spend.online.tab',
          custom: ""
        }, {
          default: _withCtx(({ isActive, isExactActive }) => [
            _createElementVNode("li", {
              class: _normalizeClass([ isActive && 'is-active', isExactActive && 'router-link-exact-active', ])
            }, [
              (_openBlock(), _createElementBlock("a", {
                key: _ctx.$i18n.locale+'spend.online.tab',
                href: _ctx.$t('spendOnline.link' )
              }, _toDisplayString(_ctx.$t('spendOnline.label')), 9, _hoisted_5))
            ], 2)
          ]),
          _: 1
        }, 8, ["to"])
      ])
    ]),
    _createVNode(_component_VoucherSelectComponent, {
      key: 'voucher_component' + _ctx.$i18n.locale,
      "selected-voucher-type": $props.voucherType,
      class: "is-inline-flex",
      onChange: $setup.onChangeVoucherType
    }, null, 8, ["selected-voucher-type", "onChange"]),
    _createVNode(_component_CategoryComponent, {
      key: 'voucher_component' + _ctx.$i18n.locale + $props.voucherType,
      "selected-voucher-type": $props.voucherType,
      "selected-category": $props.category,
      onChange: $setup.onChangeCategory
    }, null, 8, ["selected-voucher-type", "selected-category", "onChange"])
  ]))
}