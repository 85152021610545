
import {onBeforeMount, onBeforeUnmount, ref, Ref, watch} from "vue";
import LevelComponent from "@/components/level/LevelComponent.vue";
import { useRoute } from "vue-router";
/**
 * @todo To be fixed the selected functionality
 *
 */
export default {
  name: "BasicSelectComponent",
  components: { LevelComponent },
  props: {
    name: {},
    options: {
      type: Array,
      require: true,
    },
    is_loading: {},
    modelValue: {},
    dropdownArrowOrientation: {
      type: String,
      default: "left",
    },
    isDisabled: {
      type: Boolean,
      required: false,
      default: false,
    },
    highlight: {
      type: Boolean,
      required: false,
      default: false,
    },
    isClearable: {
      type: Boolean,
      required: false,
      default: false,
    }
  },
  emits: ["update:modelValue", "clear"],

  setup(props: any, { emit }: any) {
    const route = useRoute();
    const is_active: Ref<boolean> = ref(false);

    const selected: Ref<any> = ref();

    /**
     * Clear event
     */
    const onClear = () => {
      emit("clear");
      selected.value = undefined
    }

    /**
     * Force close the opened dropdown
     *
     * @return void
     */
    const closeDropdown = (): void => {
      is_active.value = false;
    };

    /**
     * When the model is loaded within the component and the model is <> from undefined, this methods
     * find the element within the options following the value key within the every single object
     *
     * @param value the key of the requested entity
     */
    const findSelected = (value: number | string | boolean) => {
      const foundOptionsIndex: number = props.options?.findIndex(
        (element: any) => element.value === value
      );

      if (foundOptionsIndex < 0) {
        return undefined;
      } else {
        return props.options[foundOptionsIndex];
      }
    };

    /**
     * The event called when the user selects an entity from the custom select dropdown
     *
     * @param index
     */
    const onSelect = (value: number | string | boolean) => {
      const foundSelectedItem = findSelected(value);
      //selected.value = foundSelectedItem;
      emit("update:modelValue", foundSelectedItem.value);
      closeDropdown();
      return {};
    };

    watch(() => props.modelValue, (newModelValue, oldModelValue) => {
        if (newModelValue) {
          const foundSelectedItem = findSelected(newModelValue);
          selected.value = foundSelectedItem;
        }
      }
    );

    onBeforeMount(() => {
      const foundSelectedItem = findSelected(props.modelValue);
      selected.value = foundSelectedItem;

      document.addEventListener('click', closeDropdown)
    });

    onBeforeUnmount(() => {
      document.removeEventListener('click', closeDropdown)
    })

    return {
      is_active,
      selected,
      onSelect,
      closeDropdown,
      findSelected,
      onClear,
    };
  },
};
