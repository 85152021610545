import { renderSlot as _renderSlot, resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, withCtx as _withCtx, createVNode as _createVNode, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_IconByImage = _resolveComponent("IconByImage")!
  const _component_LevelItemComponent = _resolveComponent("LevelItemComponent")!
  const _component_LevelComponent = _resolveComponent("LevelComponent")!

  return (_openBlock(), _createBlock(_component_LevelComponent, { class: "has-text-left " }, {
    "level-left": _withCtx(() => [
      _createVNode(_component_LevelItemComponent, null, {
        default: _withCtx(() => [
          _renderSlot(_ctx.$slots, "icon", {
            props: { icon: $props.icon, label: $props.label }
          }, () => [
            ($props.icon)
              ? (_openBlock(), _createBlock(_component_IconByImage, {
                  key: 0,
                  icon: $props.icon
                }, null, 8, ["icon"]))
              : _createCommentVNode("", true)
          ])
        ]),
        _: 3
      })
    ]),
    "level-right": _withCtx(() => [
      _createTextVNode(_toDisplayString($props.label), 1)
    ]),
    _: 3
  }))
}