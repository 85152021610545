export function useScrollingSettings() {
  return {
    vertical_settings: {
      suppressScrollY: false,
      suppressScrollX: true,
      wheelPropagation: true,
      minScrollbarLength: 147,
      maxScrollbarLength: 147,
    },
    horizontal_settings: {
      suppressScrollY: false,
      suppressScrollX: true,
      wheelPropagation: true,
      minScrollbarLength: 147,
      maxScrollbarLength: 147,
      useBothWheelAxes: true,
    },
  };
}
