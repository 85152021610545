import { renderSlot as _renderSlot, normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, createSlots as _createSlots, openBlock as _openBlock, createBlock as _createBlock } from "vue"

const _hoisted_1 = { class: "icon is-medium" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_LevelItemComponent = _resolveComponent("LevelItemComponent")!
  const _component_LevelComponent = _resolveComponent("LevelComponent")!

  return (_openBlock(), _createBlock(_component_LevelComponent, { class: "has-text-left" }, _createSlots({
    "level-right": _withCtx(() => [
      _createTextVNode(_toDisplayString($props.label), 1)
    ]),
    _: 2
  }, [
    ($props.icon)
      ? {
          name: "level-left",
          fn: _withCtx(() => [
            _createVNode(_component_LevelItemComponent, null, {
              default: _withCtx(() => [
                _renderSlot(_ctx.$slots, "icon", {
                  props: { icon: $props.icon, label: $props.label }
                }, () => [
                  _createElementVNode("span", _hoisted_1, [
                    _createElementVNode("i", {
                      class: _normalizeClass($props.icon)
                    }, null, 2)
                  ])
                ])
              ]),
              _: 3
            })
          ])
        }
      : undefined
  ]), 1024))
}