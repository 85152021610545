export const ECVCategoryMappings: Array<{id: number, code: string}> = [
  {
     'id' : 0,
     'code' : 'ecv-horeca-bar',
  },
  {
     'id' : 1,
     'code' : 'ecv-horeca-snack',
  },
  {
     'id' : 2,
     'code' : 'ecv-horeca-fast-food',
  },
  {
     'id' : 3,
     'code' : 'ecv-horeca-restaurant',
  },
  {
     'id' : 5,
     'code' : 'ecv-shop-food',
  },
  {
     'id' : 11,
     'code' : 'ecv-shop-beauty',
  },
  {
     'id' : 14,
     'code' : 'ecv-shop-fashion',
  },
  {
     'id' : 6,
     'code' : 'ecv-shop-dyi',
  },
  {
     'id' : 10,
     'code' : 'ecv-shop-interior',
  },
  {
     'id' : 15,
     'code' : 'ecv-shop-electro',
  },
  {
     'id' : 13,
     'code' : 'ecv-shop-garden',
  },
  {
     'id' : 7,
     'code' : 'ecv-shop-bike',
  },
  {
     'id' : 12,
     'code' : 'ecv-leisure',
  },
  {
     'id' : 8,
     'code' : 'ecv-shop-reuse',
  },
  {
     'id' : 16,
     'code' : 'ecv-shop-toy',
  },
  {
     'id' : 4,
     'code' : 'ecv-shop-other',
  },
  {
     'id' : 17,
     'code' : 'ecv-sport',
  },
  {
     'id' : 18,
     'code' : 'ecv-event',
  },
  {
     'id' : 19,
     'code' : 'ecv-wellness',
  },
  {
     'id': 20,
     'code': 'ecv-culture',
  },
  {
     'id': 21,
     'code': 'ecv-child',
  },
  {
     'id': 22,
     'code': 'ecv-shop-wholesale'
  },
];
