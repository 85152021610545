import { toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, createTextVNode as _createTextVNode, withCtx as _withCtx } from "vue"

const _hoisted_1 = { class: "mr-4 dropdown tooltipSearch" }
const _hoisted_2 = {
  key: 0,
  id: "tooltipBox"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_IconByFALabeled = _resolveComponent("IconByFALabeled")!
  const _component_BasicSelectComponent = _resolveComponent("BasicSelectComponent")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    (!$setup.vouchersStore.getters.getSelected && !$setup.categoryStore.getters.getSelected)
      ? (_openBlock(), _createElementBlock("span", _hoisted_2, _toDisplayString($setup.t("search.voucher.tooltip")), 1))
      : _createCommentVNode("", true),
    _createVNode(_component_BasicSelectComponent, {
      class: "mr-4",
      options: $setup.categoryStore.getters.getItems,
      modelValue: $setup.localModel,
      "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ($setup.localModel = $event)),
      "is-disabled": !$props.selectedVoucherType,
      isClearable: true,
      onClear: $setup.clearCategory,
      key: 'voucher' + $props.selectedVoucherType
    }, {
      "default-not-selected-option": _withCtx(() => [
        _createVNode(_component_IconByFALabeled, {
          label: _ctx.$t('select.shop')
        }, null, 8, ["label"])
      ]),
      option: _withCtx(({ option }) => [
        _createVNode(_component_IconByFALabeled, {
          icon: option.ico,
          label: option.label
        }, null, 8, ["icon", "label"])
      ]),
      "selected-option": _withCtx(({ option }) => [
        _createVNode(_component_IconByFALabeled, {
          icon: option.ico,
          label: option.label
        }, null, 8, ["icon", "label"])
      ]),
      "no-options": _withCtx(({}) => [
        _createTextVNode(_toDisplayString(_ctx.$t('select.shop')), 1)
      ]),
      _: 1
    }, 8, ["options", "modelValue", "is-disabled", "onClear"])
  ]))
}