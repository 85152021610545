
import { inject, watch, onMounted, getCurrentInstance } from "vue";
import { useI18n } from 'vue-i18n';
import {useRoute, useRouter } from 'vue-router';

import {useCategories} from "@/composables/CategoriesComposable";

export default {
  name: "LocaleChange",
  setup(props: any, ctx: any) { 
    const i18n: any = useI18n();
    const langStore: any = inject("langStore");
    const vouchersStore: any = inject("vouchersStore");
    const categoryStore: any = inject("categoryStore");
    const router = useRouter();
    const route = useRoute();

    const {load} = useCategories();
    //const {loadAllCategories} = useAllCategories(); #todo handle or cleanup

    /**
     * Force translation of voucher categories
     * 
     * @param vtID - the voucher type id that is already selected
     */
    const translateCategories = (vtID: string):void => {
      const voucher = parseInt(vtID);
      const voucher_type = vouchersStore.actions.findElement(voucher)?.ico;

      load(langStore.getters.getCurrentLang, voucher_type);
      //loadAllCategories(langStore.getters.getCurrentLang); #todo handle or cleanup
    };

    const changeLang = async (lang: string):Promise<void> => {
      let par = Object.assign({}, route.params);

      par.lang = lang;

      await langStore.setters.setCurrentLang(lang);
      //console.log('LOCALE ', lang, i18n, langStore.getters.getCurrentLang);

      // Translate categories dropdown items in case a voucher type is selected already
      if (vouchersStore.getters.getSelected) {
        await translateCategories(vouchersStore.getters.getSelected);
      }

      await router.push({
        params: par,
        query: {
          ...route.query
        }
      });


      i18n.locale.value = lang;
      window.location.reload()
    }

    return {
      langStore,
      changeLang,
    };
  },
};
