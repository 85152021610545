
import { ENUMVoucherTypes } from "@/Interfaces/EnumVouchers";
import VoucherSelectComponent from "@/components/elements/VoucherSelectComponent.vue";
import CategoryComponent from "@/components/elements/CategoryComponent.vue";

export default {
  name: "HomeNavigationTabs",
  components: {
    CategoryComponent,
    VoucherSelectComponent,
  },
  props: {
    voucherType: {
      type: [String],
      required: false,
      default: undefined,
    },
    category: {
      type: String,
      required: false,
      default: '',
    },
    isHome: {
      type: Boolean,
      required: false,
      default: true
    }
  },
  emits: ["voucherChange", "categoryChange"],
  setup(props: {voucherType?: string, category?: string, isHome: boolean}, {emit}: any) {

    const onChangeVoucherType = ():void => {
      emit("voucherChange");
    };

    const onChangeCategory = ():void => {
      emit("categoryChange");
    };

    return {
      onChangeVoucherType,
      onChangeCategory,
      ENUMVoucherTypes,
    };
  },
};
