
import {inject, ref, watch} from "vue";
import IconByImageLabeled from "@/components/elements/IconByImageLabeled.vue";
import BasicSelectComponent from "@/components/elements/BasicSelectComponent.vue";
import IconsByImageCollection from "@/components/elements/IconsByImageCollection.vue";
import IconByImage from "@/components/elements/IconByImage.vue";
import {useRoute, useRouter} from "vue-router";
import { categoryStore } from '@/stores/CategoryStore';

export default {
  name: "VoucherSelectComponent",
  components: {
    IconByImageLabeled,
    BasicSelectComponent,
    // LevelComponent,
    IconsByImageCollection,
    IconByImage,
  },
  emits: ["change", "update"],
  props: {
    selectedVoucherType: {
      type: String,
      required: false,
      default: undefined,
    }
  },
  /**
   *
   * @param props
   * @param emit
   */
  setup(props: any, {emit}: any) {
    /**
     * Load the needed stores we have
     */
    const vouchersStore: any = inject("vouchersStore");
    const placeStore: any = inject("placeStore");
    const searchStore: any = inject("searchStore");

    const router = useRouter();
    const route = useRoute();
    /**
     * Define the models
     */
    const localModel = ref(props.selectedVoucherType ? parseInt(props.selectedVoucherType) : undefined);

    watch(() => localModel.value, async (newLocalModel, oldLocalModel) => {
      categoryStore.setters.setSelected(null);
      await vouchersStore.setters.setSelected(newLocalModel);
      //await searchStore.setters.setSearchCriteria("");
      await placeStore.actions.clearFindings();
      router.replace({
        path: route.path,
        query: {...route.query,
          voucher_type: newLocalModel,
          category: undefined // this means the category in the route is removed when the localmodel (aka the voucher type) changes.
        }});
    });

    return {
      localModel,
      vouchersStore,
    };
  },
};
