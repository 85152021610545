
import { ENUMVoucherTypes } from "@/Interfaces/EnumVouchers";
import { PropType } from "vue";
import LevelComponent from "@/components/level/LevelComponent.vue";
import LevelItemComponent from "@/components/level/LevelItemComponent.vue";
import IconByImage from "@/components/elements/IconByImage.vue";

export default {
  name: "IconByImageLabeled",
  components: {
    LevelComponent,
    LevelItemComponent,
    IconByImage,
  },
  props: {
    label: {
      type: String,
      required: true,
    },
    icon: {
      type: String as PropType<ENUMVoucherTypes>,
      default: undefined,
    },
  },
  setup(props: { label: string; icon: ENUMVoucherTypes }) {
    return {};
  },
};
